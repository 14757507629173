import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import close_icon from "../images/closed_icon.svg"

const GrowthCalc = props => {
  // const digitOnly = /[0-9]/;
  const [state, setState] = useState({
    readonly: "readonly",
    monthlyLeadsIncrement: "50%",
    closeRateIncrement: "50%",
    projectSizeIncrement: "44%",

    monthlyLeadsInput: "",
    closeRateInput: "",
    projectSizeInput: "",

    monthlyLeadsResult: "",
    closeRateResult: "",
    projectSizeResult: "",

    annualRevenueOld: "",
    annualRevenueGrow: "",
  })
  

  // const validDigit = (evt) => {
  // 	var val = getNumber(evt.target.value)
  // 	var result;
  //     if (!digitOnly.test(val)) {
  //         result += '';
  //     } else {
  //         result = val;
  // 	}
  // 	return result;
  // }

  var getNumber = number => {
    return typeof number === "string"
      ? Number(
          number
            .replace("$", "")
            .replace("%", "")
            .replace(",", "")
        )
      : number
  }

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  var calcValue = (ele, val, inc) => {
    const input = [ele] + "Input"
    const result = [ele] + "Result"
    const increament = [ele] + "Increment"
    switch (input) {
      case "monthlyLeadsInput":
        setState({
          ...state,
          [input]: val,
          [increament]: inc,
          [result]: numberWithCommas(
            getNumber(val) + Math.round((getNumber(val) * getNumber(inc)) / 100)
          ),
          annualRevenueOld:
            "$" +
            numberWithCommas(
              getNumber(val) *
                getNumber(state.closeRateInput) *
                getNumber(state.projectSizeInput) *
                0.12
            ),
          annualRevenueGrow:
            "$" +
            numberWithCommas(
              (getNumber(val) +
                Math.round((getNumber(val) * getNumber(inc)) / 100)) *
                getNumber(state.closeRateResult) *
                getNumber(state.projectSizeResult) *
                0.12
            ),
        })
        break
      case "closeRateInput":
        setState({
          ...state,
          [input]: val,
          [increament]: inc,
          [result]:
            numberWithCommas(
              getNumber(val) +
                Math.round((getNumber(val) * getNumber(inc)) / 100)
            ) + "%",
          annualRevenueOld:
            "$" +
            numberWithCommas(
              getNumber(val) *
                getNumber(state.monthlyLeadsInput) *
                getNumber(state.projectSizeInput) *
                0.12
            ),
          annualRevenueGrow:
            "$" +
            numberWithCommas(
              (getNumber(val) +
                Math.round((getNumber(val) * getNumber(inc)) / 100)) *
                getNumber(state.monthlyLeadsResult) *
                getNumber(state.projectSizeResult) *
                0.12
            ),
        })
        break
      case "projectSizeInput":
        setState({
          ...state,
          [input]: val,
          [increament]: inc,
          [result]: numberWithCommas(
            getNumber(val) + Math.round((getNumber(val) * getNumber(inc)) / 100)
          ),
          annualRevenueOld:
            "$" +
            numberWithCommas(
              getNumber(val) *
                getNumber(state.monthlyLeadsInput) *
                getNumber(state.closeRateInput) *
                0.12
            ),
          annualRevenueGrow:
            "$" +
            numberWithCommas(
              (getNumber(val) +
                Math.round((getNumber(val) * getNumber(inc)) / 100)) *
                getNumber(state.monthlyLeadsResult) *
                parseFloat(state.closeRateResult) *
                0.12
            ),
        })
        break
      default:
        break
    }
  }

  const handleValidValue = evt => {
    const eleId = evt.target.id
    const eleval = evt.target.value

    if (eleval > 0) {
      switch (eleId) {
        case "close-rate":
          setState({
            ...state,
            closeRateInput: eleval + "%",
            // closeRateResult: state.closeRateResult + '%'
          })
          break
        case "project-size":
          setState({
            ...state,
            projectSizeInput: "$" + eleval,
            projectSizeResult: "$" + state.projectSizeResult,
          })
          break
        default:
          break
      }
    }
  }

  const handleChangeInput = evt => {
    // const inputValue = validDigit(evt);
    const inputValue = evt.target.value
    const inputEle = evt.target.name
    const inputId = inputEle.split("Input")[0]
    calcValue(inputId, inputValue, state[inputId + "Increment"])
  }

  // const handleCheckInput = (evt) => {
  // 	const eleName = evt.target.name;
  // 	const inputId = eleName.split("Increment")[0];
  // 	const inputEle = inputId + 'Input';
  // 	const inputValue = state[inputEle];

  // 	// get increament value
  // 	const eleValue = evt.target.value;
  // 	// set input checkbox true
  // 	calcValue(inputId, inputValue, eleValue);
  // }

  if (props.popData.length !== 0) {
    return (
      <Modal
        show={props.visiblity}
        animation={true}
        centered={true}
        size="full"
        id="calcPopup"
      >
        <div className="muppet-fart">
        <div className="heading_bg">
          <button
            className="close text-white"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.handleClose}
          >
            <img src={close_icon} width="25" alt="close icon" />
          </button>
          <h2 className="h2 text-white font-weight-bold">
            Business Growth Calculator
          </h2>
        </div>
        <div className="modal_content">
          <div className="p-md-5 pb-0 pt-sm-4">
            <form action="" className="calc_form">
              <div className="row">
                <div className="mr-auto px-3 pt-3 current_growth d-none d-lg-block">
                  Current
                </div>
                <div className="ml-auto pt-3 growth_result col-sm-3 d-none d-lg-block">
                  Result
                </div>
              </div>
              <div className="row border-bottom">
                <div className="col-lg-3 py-3 px-5 px-md-3">
                  <label
                    htmlFor="monthly-leads"
                    className="text-sm theme-text d-block"
                  >
                    Monthly Leads
                  </label>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      name="monthlyLeadsInput"
                      className="border-radius5 calc-input"
                      id="monthly-leads"
                      onChange={handleChangeInput}
                    />
                  </div>
                </div>
                <div className="col-lg-6 py-3 px-5 px-md-3 d-flex align-items-end">
                  <div className="d-flex align-items-center">
                    <div className="pr-3">
                      <span className="h4">x</span>
                    </div>
                    <div className="pr-3 text-blue h2">50%</div>
                    <small className="pr-3">
                      Advertising payment <br />
                      options can increase leads.*
                    </small>
                  </div>
                </div>
                <div className="col-lg-3 bg_blue monthly-leads-result px-5 px-md-4 py-3 py-md-3">
                  <label
                    htmlFor="monthly-leads-result"
                    className="text-sm theme-text text-white d-block"
                  >
                    Monthly Leads
                  </label>
                  <input
                    type="text"
                    className="border-radius5 calc-output"
                    name="monthlyLeadsResult"
                    id="monthly-leads-result"
                    value={state.monthlyLeadsResult}
                    readOnly={state.readonly}
                  />
                </div>
              </div>
              <div className="row border-bottom">
                <div className="col-lg-3 py-3 px-5 px-md-3">
                  <label
                    htmlFor="close-rate"
                    className="text-sm theme-text d-block"
                  >
                    Close Rate
                  </label>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      name="closeRateInput"
                      className="border-radius5 calc-input"
                      id="close-rate"
                      value={state.closeRateInput}
                      onChange={handleChangeInput}
                      onBlur={handleValidValue}
                    />
                  </div>
                </div>
                <div className="col-lg-6 py-3 px-5 px-md-3 d-flex align-items-end">
                  <div className="d-flex align-items-center">
                    <div className="pr-3">
                      <span className="h4">x</span>
                    </div>
                    <div className="pr-3 text-blue h2">50%</div>
                    <small className="pr-3">
                      Offering flexible payment <br />
                      options can boost close rate.*
                    </small>
                  </div>
                </div>
                <div className="col-lg-3 bg_blue close-rate-result px-5 px-md-4 py-3 py-md-3">
                  <label
                    htmlFor="close-rate-result"
                    className="text-sm theme-text text-white d-block"
                  >
                    Close Rate
                  </label>
                  <input
                    type="text"
                    name="closeRateResult"
                    className="border-radius5 calc-output"
                    id="close-rate-result"
                    value={state.closeRateResult}
                    readOnly={state.readonly}
                  />
                </div>
              </div>
              <div className="row border-bottom">
                <div className="col-lg-3 py-3 px-5 px-md-3">
                  <label
                    htmlFor="project-size"
                    className="text-sm theme-text d-block"
                  >
                    Average Project Size
                  </label>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      name="projectSizeInput"
                      className="border-radius5 calc-input"
                      id="project-size"
                      value={state.projectSizeInput}
                      onChange={handleChangeInput}
                      onBlur={handleValidValue}
                    />
                  </div>
                </div>
                <div className="col-lg-6 py-3 px-5 px-md-3 d-flex align-items-end">
                  <div className="d-flex align-items-center">
                    <div className="pr-3">
                      <span className="h4">x</span>
                    </div>
                    <div className="pr-3 text-blue h2">44%</div>
                    <small className="pr-3">
                      Offering flexible payment options <br />
                      can grow average project size.*
                    </small>
                  </div>
                </div>
                <div className="col-lg-3 bg_blue project-result px-5 px-md-4 py-3 py-md-3">
                  <label
                    htmlFor="project-size-result"
                    className="text-sm theme-text text-white d-block"
                  >
                    Average Project Size
                  </label>
                  <input
                    type="text"
                    name="projectSizeResult"
                    className="border-radius5 calc-output"
                    id="project-size-result"
                    value={state.projectSizeResult}
                    readOnly={state.readonly}
                  />
                </div>
              </div>

              <div className="row border-bottom">
                <div className="col-lg-3 py-3 px-5 px-md-3">
                  <label
                    htmlFor="annual-revenue"
                    className="text-sm theme-text d-block"
                  >
                    Annual Revenue
                  </label>
                  <div className="align-items-center">
                    <input
                      type="text"
                      name="annualRevenueOld"
                      className="border-radius5 calc-output"
                      id="annual-revenue"
                      value={state.annualRevenueOld}
                      readOnly="readonly"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text-sm theme-text text-blue increasing-incoming pt-3 pb-3 px-5 pl-md-0 pr-md-3">
                    By increasing your incoming leads, close rate and average
                    job size, watch your annual revenue grow.
                  </div>
                </div>
                <div className="col-lg-3 bg_blue annual-revenue-result px-5 px-md-4 py-3 py-md-3">
                  <label
                    htmlFor="annual-revenue-result"
                    className="text-sm theme-text text-white d-block"
                  >
                    Annual Revenue
                  </label>
                  <input
                    type="text"
                    name="annualRevenueGrow"
                    className="border-radius5 calc-output"
                    id="annual-revenue-result"
                    value={state.annualRevenueGrow}
                    readOnly={state.readonly}
                  />
                </div>
              </div>
            </form>
            <div className="row">
              <div className="calc_bottom px-5 px-md-3">
                <p className="mb-4">
                On average, our contractors have seen significant increase in inbound leads, close rates, average job sizes and annual revenue by offering payment options to every customer. This calculator is provided for reference only. This is not a guarantee of your actual results. Actual performance will vary based on individual circumstances. The results of the calculator are hypothetical and are for illustrative purposes only.
                </p>

                <small>
                  <em>
                    *Based on the Brickyard Study commissioned by Regions, 2018
                  </em>
                </small>
              </div>
            </div>
          </div>
        </div>
        </div>
      </Modal>
    )
  } else {
    return (
      <div className="modal fade show" style={{ display: props.visiblity }}>
        &nbsp;
      </div>
    )
  }
}
export default GrowthCalc
